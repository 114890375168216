<template>
  <div>
    <v-row dense>
      <v-col md="12" cols="12">
        <v-form ref="form" class="multi-col-validation">
          <v-row dense>
            <v-col md="3" cols="12">
              <v-card flat dense>
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h5 class="font-weight-light">SEARCHING SECTION</h5>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">
                  <v-row dense>
                    <v-col md="12" cols="12">
                      <v-select
                        v-model="month_of_id"
                        class="mx-2"
                        :items="month_of_items"
                        item-value="year"
                        item-text="year"
                        label="Year Of"
                        required
                        :rules="rules.combobox_rule"
                        dense
                        @change="selected_month"
                      ></v-select>
                    </v-col>
                    <v-col md="12" cols="12">
                      <v-select
                        v-model="type_of_employee"
                        class="mx-2"
                        :items="[
                          { id: 1, name: 'Active' },
                          { id: 2, name: 'Temporary Deactivate' },
                          { id: 3, name: 'Permanent Deactivated' },
                        ]"
                        label="Type of Employee"
                        item-value="id"
                        item-text="name"
                        required
                        :rules="rules.combobox_rule"
                        dense
                        @change="selected_month"
                      ></v-select>
                    </v-col>
                    <v-col md="12" cols="12">
                      <v-select
                        class="mx-2"
                        v-model="category_id"
                        dense
                        label="Category"
                        :items="category_items"
                        item-value="id"
                        item-text="category"
                        :rules="rules.combobox_rule"
                        @change="selected_month"
                      ></v-select>
                    </v-col>
                    <v-col md="12" cols="12">
                      <v-select
                        v-model="batch_no"
                        class="mx-2"
                        :items="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
                        label="Batch"
                        required
                        dense
                        @change="selected_month"
                      ></v-select>
                    </v-col>

                    <v-col md="12" cols="12" v-if="category_id > 0 && false">
                      <v-autocomplete
                        class="mx-2"
                        v-model="search"
                        :items="search_items"
                        :loading="isLoading"
                        @keyup.enter="searching($event.target.value)"
                        hide-no-data
                        hide-selected
                        item-text="name"
                        item-value="id"
                        label="Search"
                        placeholder="Press Enter to Search "
                        :prepend-icon="icons.mdiAccountSearchOutline"
                        @change="get_search_items_info"
                      ></v-autocomplete>
                    </v-col>
                    <v-col md="12" cols="12">
                      <h2>List of Employee</h2>
                      <v-data-table
                        dense
                        class="mt-2"
                        :headers="headers"
                        :items="employee_items"
                        :height="330"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:item="{ item }">
                          <tr @click="activerow(item)"
                              :class="{ 'info white--text': item.id === selectedDepositId }">
                            <td class="text-center">
                              {{ item.name }}
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="9" cols="12">
              <v-card flat dense>
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h5 class="font-weight-light">EMPLOYEE'S INFORMATION</h5>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">
                  <v-row dense>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="full_name"
                        label="Full Name"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="branch"
                        label="Branch"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="position"
                        label="Position"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="status"
                        label="Status"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="date_of_employment"
                        label="Date of Employment"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                      <v-text-field
                        v-model="employment_duration"
                        label="Employee Duration"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-row>
                <v-col md="5" cols="12">
                  <v-card flat dense>
                    <v-toolbar dense dark color="primary">
                      <v-toolbar-title><h5 class="font-weight-light">13th MONTH PAY COMPUTATION
                        SECTION</h5></v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="mt-3">
                      <v-row dense>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="jan_raw"
                            label="Jan."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="feb_raw"
                            label="Feb."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="mar_raw"
                            label="Mar."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="apr_raw"
                            label="Apr."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="may_raw"
                            label="May"
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="jun_raw"
                            label="Jun."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="jul_raw"
                            label="Jul."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="aug_raw"
                            label="Aug."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="sep_raw"
                            label="Sep."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="oct_raw"
                            label="Oct."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="nov_raw"
                            label="Nov."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-if="!is_remove_dec"
                            v-model="dec_raw"
                            label="Dec."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>

                        <v-col md="12" cols="12">
                          <v-checkbox
                            v-model="is_remove_dec"
                            label="Remove December?"
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-checkbox>
                        </v-col>
                        <v-col md="12" cols="12">
                          <v-text-field
                            v-if="!is_allowance"
                            background-color="info"
                            style="font-size: x-large"
                            v-model="gross_salary_formated"
                            label="Total"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                          <v-text-field
                            v-else
                            background-color="info"
                            style="font-size: x-large"
                            v-model="new_gross_salary"
                            label="Total"
                            dense
                            outlined
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                            type="number"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col md="7" cols="12">
                  <v-card flat dense>
                    <v-toolbar dense dark color="primary">
                      <v-toolbar-title><h5 class="font-weight-light">13th MONTH PAY DEDUCTION
                        SECTION</h5></v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="mt-3">
                      <v-row dense>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_sss"
                            label="SSS #"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_loan"
                            label="Loans"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_auto_loan"
                            label="Auto Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_philhealth"
                            label="PhilHealth"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_ca"
                            label="Cash Advance"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_coop_loan"
                            label="COOP Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_pagibig"
                            label="Pag-Ibig"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_plan"
                            label="Plan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_coop_save"
                            label="COOP Save"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_tax"
                            label="TAX"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_motor"
                            label="Motor Finance"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_orec"
                            label="Other Receivable"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_sss_loan"
                            label="SSS Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_pagibig_loan"
                            label="Pag-Ibig Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-model="deduction_others"
                            label="Others"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4" cols="12">
                          <v-text-field
                            v-if="deduction_others > 0"
                            v-model="other_details"
                            label="Specify Others"
                            dense
                            outlined
                            :rules="rules.default_max_45_character_and_no_empty_rule"
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12">
                          <v-text-field
                            background-color="warning"
                            style="font-size: xx-large"
                            v-model="net_salary_formated"
                            label="Net 13th Month Pay"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- alert -->
                <v-col cols="12" v-show="alert">
                  <v-alert color="warning" text class="mb-0">
                    <div class="d-flex align-start">
                      <v-icon color="warning">
                        {{ icons.mdiAlertOutline }}
                      </v-icon>

                      <div class="ms-3">
                        <p class="text-base font-weight-medium mb-1">
                          {{ alert_message }}
                        </p>
                      </div>
                    </div>
                  </v-alert>
                </v-col>
                <v-col cols="12" md="12" v-if="!is_final">
                  <v-btn class="w-full" color="primary" @click="save_employee" v-if="!saving"> Save
                    13th Month Pay
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="saving"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col md="12" cols="12">
        <v-card flat dense>
          <v-card-text class="mt-3">
            <v-row dense>
              <v-col md="12" cols="12">
                <h2>
                  13th Month Pay Data
                  <v-btn class="mt-2" color="success" @click="final_salary"
                         v-if="!saving && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="saving"
                  ></v-progress-circular>
                </h2>
                <v-simple-table dense class="mt-2">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-center text-uppercase">Name</th>
                      <th class="text-center text-uppercase">Branch</th>
                      <th class="text-center text-uppercase">Position</th>
                      <th class="text-center text-uppercase">Gross</th>
                      <th class="text-center text-uppercase">Deductions</th>
                      <th class="text-center text-uppercase">Net</th>
                      <th class="text-center text-uppercase">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in salaries_items" :key="item.id">
                      <td class="text-center">
                        {{
                        item.employee.last_name + ',' + item.employee.first_name + ' ' +
                        item.employee.middle_name
                        }}
                      </td>
                      <td class="text-center">
                        {{ item.employee.branch.branch_code }}
                      </td>
                      <td class="text-center">
                        {{ item.employee.employee_position.position }}
                      </td>
                      <td class="text-center">
                        {{ formatPrice(item.total_amount) }}
                      </td>
                      <td class="text-center">
                        {{
                        formatPrice(
                        parseFloat(item.deduction_sss) +
                        parseFloat(item.deduction_philhealth) +
                        parseFloat(item.deduction_pagibig) +
                        parseFloat(item.deduction_loan) +
                        parseFloat(item.deduction_auto_loan) +
                        parseFloat(item.deduction_coop_loan) +
                        parseFloat(item.deduction_late) +
                        parseFloat(item.deduction_coop_save) +
                        parseFloat(item.deduction_ca) +
                        parseFloat(item.deduction_plan) +
                        parseFloat(item.deduction_others) +
                        parseFloat(item.deduction_tax) +
                        parseFloat(item.deduction_orec) +
                        parseFloat(item.deduction_sss_loan) +
                        parseFloat(item.deduction_pagibig_loan) +
                        parseFloat(item.deduction_motor),
                        )
                        }}
                      </td>
                      <td class="text-center">
                        {{ formatPrice(item.net_amount) }}
                      </td>
                      <td class="text-center">
                        <div v-if="item.is_approved === 0">
                          <v-icon class="mr-2" color="error" @click="actions(item)">
                            {{ icons.mdiPencilOutline }}
                          </v-icon>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="edit_dialog" persistent max-width="80%">
      <v-card>
        <edit-salaries-components v-on:data="selected_month" :key="this.key"
                                  :value="this.edit_value">
        </edit-salaries-components>
      </v-card>
      <v-btn class="mt-4" color="error" @click="edit_dialog = false"> Close</v-btn>
    </v-dialog>

    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiPencilOutline} from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import EditSalariesComponents
    from '@/views/input_interface/salary_and_thirten_pay/thirten_pay/EditThirtenComponents'

  const initialState = () => {
    return {
      key: 0,
      saving: false,
      alert: false,
      is_remove_dec: false,
      is_allowance: false,
      alert_message: '',
      search_member: [],

      edit_dialog: false,
      edit_value: {},

      month_of_id: '',
      month_of_items: [],
      type_of_employee: '',
      category_id: '',
      category_items: [],

      batch_no: '',
      isLoading: false,
      search: '',
      search_items: [],
      employee_items: [],
      full_name: '',
      branch: '',
      position: '',
      status: '',
      date_of_employment: '',
      employment_duration: '',

      jan: 0,
      feb: 0,
      mar: 0,
      apr: 0,
      may: 0,
      jun: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      oct: 0,
      nov: 0,
      dec: 0,

      jan_raw: 0,
      feb_raw: 0,
      mar_raw: 0,
      apr_raw: 0,
      may_raw: 0,
      jun_raw: 0,
      jul_raw: 0,
      aug_raw: 0,
      sep_raw: 0,
      oct_raw: 0,
      nov_raw: 0,
      dec_raw: 0,

      gross_salary: 0,
      gross_salary_formated: '0.00',
      new_gross_salary: '0.00',

      deduction_sss: 0,
      deduction_loan: 0,
      deduction_auto_loan: 0,
      deduction_philhealth: 0,
      deduction_ca: 0,
      deduction_coop_loan: 0,
      deduction_pagibig: 0,
      deduction_plan: 0,
      deduction_coop_save: 0,
      deduction_late: 0,
      deduction_tax: 0,
      no_of_absent: 0,
      deduction_others: 0,
      deduction_motor: 0,
      deduction_pagibig_loan: 0,
      deduction_sss_loan: 0,
      deduction_orec: 0,
      other_details: '',
      net_salary: 0,
      net_salary_formated: '0.00',
      salaries_items: [],
      data: [],
      selectedDepositId: -1,
      particulars: '',
      category_text: '',
      bank_type: '',
      bank_account_name: '',
      bank_account_no: '',
      headers: [{text: 'NAME', value: 'id', sortable: false}],

      read_loans_only: false,
      read_auto_loans_only: false,
      read_ca_only: false,
      read_other_only: false,
      read_motor_only: false,
      read_plan_only: false,

      is_final: true,
    }
  }
  export default {
    components: {
      snackBarDialog,
      EditSalariesComponents,
    },
    setup() {
      return {
        icons: {
          mdiPencilOutline,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['name']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('thirten_month_pay', ['initialize_thirten_month_payroll', 'register_thirten_month_payroll', 'list_of_pending_thirten_month_pay', 'approved_thirten_month_pay']),
      ...mapActions('salaries_and_wages', [
        'initialize_salaries_employee',
        'salaries_data',
        'register_new_salary',
        'salaries_data_approved',
      ]),
      initialize_data() {
        this.initialize_salaries_employee()
          .then(response => {
            this.month_of_items = response.data[0].month_of
            this.category_items = response.data[0].category
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      reset() {
        this.saving = false
        this.particulars = ''
        this.full_name = ''
        this.branch = ''
        this.position = ''
        this.status = ''
        this.date_of_employment = ''
        this.employment_duration = ''
        this.bank_type = ''
        this.bank_account_name = ''
        this.bank_account_no = ''

        this.jan = 0
        this.feb = 0
        this.mar = 0
        this.apr = 0
        this.may = 0
        this.jun = 0
        this.jul = 0
        this.aug = 0
        this.sep = 0
        this.oct = 0
        this.nov = 0
        this.dec = 0

        this.gross_salary = 0
        this.gross_salary_formated = '0.00'

        this.deduction_sss = 0
        this.deduction_loan = 0
        this.deduction_auto_loan = 0
        this.deduction_philhealth = 0
        this.deduction_ca = 0
        this.deduction_coop_loan = 0
        this.deduction_pagibig = 0
        this.deduction_plan = 0
        this.deduction_coop_save = 0
        this.deduction_late = 0
        this.deduction_tax = 0
        this.deduction_motor = 0
        this.deduction_sss_loan = 0
        this.deduction_pagibig_loan = 0
        this.deduction_orec = 0
        this.no_of_absent = 0
        this.deduction_others = 0
        this.other_details = ''
        this.net_salary = 0
        this.net_salary_formated = '0.00'
        this.selectedDepositId = -1
      },
      activerow(value) {
        this.data = value
        this.selectedDepositId = value.id
        this.full_name = value.name
        this.branch = value.employee_details.branch.branch_code
        this.position = value.employee_details.employee_position.position
        this.status = value.employee_details.status
        this.date_of_employment = moment(value.employee_details.date_of_employment, 'YYYY-MM-DD').format('MMMM DD, YYYY')
        var a = moment()
        var b = moment(value.employee_details.date_of_employment, 'YYYY-MM-DD')

        var years = a.diff(b, 'year')
        b.add(years, 'years')

        var months = a.diff(b, 'months')
        b.add(months, 'months')

        var days = a.diff(b, 'days')
        this.employment_duration = years + ' years ' + months + ' months ' + days + ' days'
        this.bank_type = value.employee_details.bank_type_1
        this.bank_account_name = value.employee_details.bank_account_1
        this.bank_account_no = value.employee_details.bank_account_no_1
        this.jan = this.formatPrice(value.jan_amount)
        this.feb = this.formatPrice(value.feb_amount)
        this.mar = this.formatPrice(value.mar_amount)
        this.apr = this.formatPrice(value.apr_amount)
        this.may = this.formatPrice(value.may_amount)
        this.jun = this.formatPrice(value.jun_amount)
        this.jul = this.formatPrice(value.jul_amount)
        this.aug = this.formatPrice(value.aug_amount)
        this.sep = this.formatPrice(value.sep_amount)
        this.oct = this.formatPrice(value.oct_amount)
        this.nov = this.formatPrice(value.nov_amount)
        this.dec = this.formatPrice(value.dec_amount)

        this.jan_raw = value.jan_amount
        this.feb_raw = value.feb_amount
        this.mar_raw = value.mar_amount
        this.apr_raw = value.apr_amount
        this.may_raw = value.may_amount
        this.jun_raw = value.jun_amount
        this.jul_raw = value.jul_amount
        this.aug_raw = value.aug_amount
        this.sep_raw = value.sep_amount
        this.oct_raw = value.oct_amount
        this.nov_raw = value.nov_amount
        this.dec_raw = value.dec_amount

        this.gross_salary = value.total_amount
        this.new_gross_salary = 0
        this.is_allowance = value.total_amount === 0
        this.computation_of_salary()
      },
      selected_month() {
        this.reset()
        this.edit_dialog = false
        this.category_text = ''
        if (this.category_items.length > 0) {
          var index = this.category_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.category_id)
          if (index != -1) {
            this.category_text = this.category_items[index].category
          }
        }
        const data = new FormData()
        data.append('year', this.month_of_id);
        data.append('remarks', this.type_of_employee === 1 ? 'Active' : (this.type_of_employee === 2 ? 'Temporary Deactivate' : this.type_of_employee === 3 ? 'Permanent Deactivated' : ''));
        data.append('category_id', this.category_id);
        data.append('is_monitoring', 0);
        this.initialize_thirten_month_payroll(data)
          .then(response => {
            this.employee_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
        data.append('batch_no', this.batch_no);
        this.list_of_pending_thirten_month_pay(data)
          .then(response => {
            this.salaries_items = response.data[0].data
            this.is_final = response.data[0].final_counter > 0
          })
          .catch(error => {
            console.log(error)
          })
      },
      computation_of_salary() {
        this.check_if_input_number_correct()

        var deductions =
          parseFloat(this.deduction_sss) +
          parseFloat(this.deduction_loan) +
          parseFloat(this.deduction_auto_loan) +
          parseFloat(this.deduction_philhealth) +
          parseFloat(this.deduction_ca) +
          parseFloat(this.deduction_coop_loan) +
          parseFloat(this.deduction_pagibig) +
          parseFloat(this.deduction_plan) +
          parseFloat(this.deduction_coop_save) +
          parseFloat(this.deduction_late) +
          parseFloat(this.deduction_tax) +
          parseFloat(this.deduction_motor) +
          parseFloat(this.deduction_sss_loan) +
          parseFloat(this.deduction_pagibig_loan) +
          parseFloat(this.deduction_orec) +
          parseFloat(this.deduction_others)

        var total_amounttt = (parseFloat(this.jan_raw)
          + parseFloat(this.feb_raw)
          + parseFloat(this.mar_raw)
          + parseFloat(this.apr_raw)
          + parseFloat(this.may_raw)
          + parseFloat(this.jun_raw)
          + parseFloat(this.jul_raw)
          + parseFloat(this.aug_raw)
          + parseFloat(this.sep_raw)
          + parseFloat(this.oct_raw)
          + parseFloat(this.nov_raw)
          + parseFloat(this.dec_raw)) / 12;
        if (this.is_remove_dec) {
          total_amounttt = (parseFloat(this.jan_raw)
            + parseFloat(this.feb_raw)
            + parseFloat(this.mar_raw)
            + parseFloat(this.apr_raw)
            + parseFloat(this.may_raw)
            + parseFloat(this.jun_raw)
            + parseFloat(this.jul_raw)
            + parseFloat(this.aug_raw)
            + parseFloat(this.sep_raw)
            + parseFloat(this.oct_raw)
            + parseFloat(this.nov_raw)) / 12;
        }
        this.gross_salary = total_amounttt
        this.net_salary = parseFloat(total_amounttt) - parseFloat(deductions)

        this.gross_salary_formated = this.formatPrice(total_amounttt)

        if (this.is_allowance) {
          this.gross_salary = parseFloat(this.new_gross_salary)
          this.net_salary = parseFloat(this.new_gross_salary) - parseFloat(deductions)

          this.gross_salary_formated = this.formatPrice(this.new_gross_salary)
        }
        this.net_salary_formated = this.formatPrice(this.net_salary)
      },
      check_if_input_number_correct() {
        if (this.no_of_days > 31 || this.no_of_days < 0 || this.no_of_days === '') {
          this.no_of_days = 0
        }
        if (this.holiday_pay < 0 || this.holiday_pay === '') {
          this.holiday_pay = 0
        }
        if (this.rice_allowance < 0 || this.rice_allowance === '') {
          this.rice_allowance = 0
        }
        if (this.birthday_allowances < 0 || this.birthday_allowances === '') {
          this.birthday_allowances = 0
        }
        if (this.trainee_allowances < 0 || this.trainee_allowances === '') {
          this.trainee_allowances = 0
        }
        if (this.incentives_allowances < 0 || this.incentives_allowances === '') {
          this.incentives_allowances = 0
        }
        if (this.bod_allowance < 0 || this.bod_allowance === '') {
          this.bod_allowance = 0
        }
        if (this.deduction_sss < 0 || this.deduction_sss === '') {
          this.deduction_sss = 0
        }
        if (this.deduction_loan < 0 || this.deduction_loan === '') {
          this.deduction_loan = 0
        }
        if (this.deduction_auto_loan < 0 || this.deduction_auto_loan === '') {
          this.deduction_auto_loan = 0
        }
        if (this.deduction_philhealth < 0 || this.deduction_philhealth === '') {
          this.deduction_philhealth = 0
        }
        if (this.deduction_ca < 0 || this.deduction_ca === '') {
          this.deduction_ca = 0
        }
        if (this.deduction_coop_loan < 0 || this.deduction_coop_loan === '') {
          this.deduction_coop_loan = 0
        }
        if (this.deduction_pagibig < 0 || this.deduction_pagibig === '') {
          this.deduction_pagibig = 0
        }
        if (this.deduction_plan < 0 || this.deduction_plan === '') {
          this.deduction_plan = 0
        }
        if (this.deduction_coop_save < 0 || this.deduction_coop_save === '') {
          this.deduction_coop_save = 0
        }
        if (this.deduction_late < 0 || this.deduction_late === '') {
          this.deduction_late = 0
        }
        if (this.no_of_absent < 0 || this.no_of_absent === '') {
          this.no_of_absent = 0
        }
        if (this.deduction_tax < 0 || this.deduction_tax === '') {
          this.deduction_tax = 0
        }
        if (this.deduction_motor < 0 || this.deduction_motor === '') {
          this.deduction_motor = 0
        }
        if (this.deduction_others < 0 || this.deduction_others === '') {
          this.deduction_others = 0
        }
        if (this.deduction_pagibig_loan < 0 || this.deduction_pagibig_loan === '') {
          this.deduction_pagibig_loan = 0
        }
        if (this.deduction_sss_loan < 0 || this.deduction_sss_loan === '') {
          this.deduction_sss_loan = 0
        }
        if (this.deduction_orec < 0 || this.deduction_orec === '') {
          this.deduction_orec = 0
        }
        if (this.new_gross_salary < 0 || this.new_gross_salary === '') {
          this.new_gross_salary = 0
        }
      },
      searching(value) {
        this.search_members_unpaid({
          search_word: value,
        }).then(response => {
          this.search_items = response.data
        })
      },
      get_search_items_info() {
        if (this.search_items.length > 0) {
          var index = this.search_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.search.id)
          if (index != -1) {
            this.selected_search_items = this.search_items[index]
            this.id_no = this.selected_search_items.id_no
            this.name = this.selected_search_items.name
          } else {
            this.name = ''
            this.id_no = ''
          }
        } else {
          this.name = ''
          this.id_no = ''
        }
      },
      actions(item) {
        this.edit_dialog = true
        this.edit_value = item
        this.key++
      },
      save_employee() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('category_id', this.category_id)
          data.append('employee_id', this.selectedDepositId)
          data.append('year', this.month_of_id)
          data.append('batch_no', this.batch_no)
          data.append('is_bunos_only', this.is_allowance ? 1 : 0)

          data.append('jan_data', JSON.stringify(this.data.jan_data))
          data.append('jan_amount', this.data.jan_amount)
          data.append('feb_data', JSON.stringify(this.data.feb_data))
          data.append('feb_amount', this.data.feb_amount)
          data.append('mar_data', JSON.stringify(this.data.mar_data))
          data.append('mar_amount', this.data.mar_amount)
          data.append('apr_data', JSON.stringify(this.data.apr_data))
          data.append('apr_amount', this.data.apr_amount)
          data.append('may_data', JSON.stringify(this.data.may_data))
          data.append('may_amount', this.data.may_amount)
          data.append('jun_data', JSON.stringify(this.data.jun_data))
          data.append('jun_amount', this.data.jun_amount)
          data.append('jul_data', JSON.stringify(this.data.jul_data))
          data.append('jul_amount', this.data.jul_amount)
          data.append('aug_data', JSON.stringify(this.data.aug_data))
          data.append('aug_amount', this.data.aug_amount)
          data.append('sep_data', JSON.stringify(this.data.sep_data))
          data.append('sep_amount', this.data.sep_amount)
          data.append('oct_data', JSON.stringify(this.data.oct_data))
          data.append('oct_amount', this.data.oct_amount)
          data.append('nov_data', JSON.stringify(this.data.nov_data))
          data.append('nov_amount', this.data.nov_amount)
          data.append('dec_data', JSON.stringify(this.data.dec_data))
          data.append('dec_amount', this.is_remove_dec ? 0 : this.data.dec_amount)
          data.append('total_amount', this.gross_salary)
          data.append('remarks', this.status + '' + (this.type_of_employee === 2 ? ' - Temporary Deactivate' : (this.type_of_employee === 3 ? ' - Permanent Deactivated' : '')))

          data.append('deduction_sss', this.deduction_sss)
          data.append('deduction_philhealth', this.deduction_philhealth)
          data.append('deduction_pagibig', this.deduction_pagibig)
          data.append('deduction_loan', this.deduction_loan)
          data.append('deduction_auto_loan', this.deduction_auto_loan)
          data.append('deduction_coop_loan', this.deduction_coop_loan)
          data.append('deduction_late', this.deduction_late)
          data.append('deduction_coop_save', this.deduction_coop_save)
          data.append('deduction_ca', this.deduction_ca)
          data.append('deduction_plan', this.deduction_plan)
          data.append('deduction_others', this.deduction_others)
          data.append('other_details', this.other_details)
          data.append('deduction_tax', this.deduction_tax)
          data.append('deduction_motor', this.deduction_motor)
          data.append('deduction_pagibig_loan', this.deduction_pagibig_loan)
          data.append('deduction_sss_loan', this.deduction_sss_loan)
          data.append('deduction_orec', this.deduction_orec)

          data.append('net_amount', this.net_salary)

          this.register_thirten_month_payroll(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.selected_month()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      final_salary() {
        this.saving = true
        this.alert = false
        const data = new FormData()
        data.append('category_id', this.category_id)
        data.append('year', this.month_of_id)
        data.append('batch_no', this.batch_no)
        this.approved_thirten_month_pay(data)
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: response.data,
            })
            this.selected_month()
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      },
    },
  }
</script>
