<template>
  <div>
    <v-row dense>
      <v-col
        md="12"
        cols="12"
      >
        <v-form ref="form" class="multi-col-validation">
          <v-row dense>
            <v-col
              md="12"
              cols="12"
            >
              <v-card
                flat
                dense
              >
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h5 class="font-weight-light">EMPLOYEE'S INFORMATION</h5>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">
                  <v-row dense>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="full_name"
                        label="Full Name"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="branch"
                        label="Branch"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="position"
                        label="Position"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="status"
                        label="Status"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="date_of_employment"
                        label="Date of Employment"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="employment_duration"
                        label="Employee Duration"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-row>
                <v-col md="5"
                       cols="12">
                  <v-card
                    flat
                    dense
                  >
                    <v-toolbar dense dark color="primary">
                      <v-toolbar-title><h5 class="font-weight-light">COMPENSATION SECTION</h5>
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="mt-3">
                      <v-row dense>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="jan"
                            label="Jan."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="feb"
                            label="Feb."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="mar"
                            label="Mar."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="apr"
                            label="Apr."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="may"
                            label="May"
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="jun"
                            label="Jun."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="jul"
                            label="Jul."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="aug"
                            label="Aug."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="sep"
                            label="Sep."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="oct"
                            label="Oct."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="nov"
                            label="Nov."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-text-field
                            v-model="dec"
                            label="Dec."
                            dense
                            outlined
                            @change="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12">
                          <v-text-field
                            v-if="!is_allowance"
                            background-color="info"
                            style="font-size: x-large"
                            v-model="gross_salary_formated"
                            label="Total"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                          <v-text-field
                            v-else
                            background-color="info"
                            style="font-size: x-large"
                            v-model="new_gross_salary"
                            label="Total"
                            dense
                            outlined
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                            type="number"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col md="7"
                       cols="12">
                  <v-card
                    flat
                    dense
                  >
                    <v-toolbar dense dark color="primary">
                      <v-toolbar-title><h5 class="font-weight-light">13th MONTH PAY DEDUCTION
                        SECTION</h5>
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="mt-3">
                      <v-row dense>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_sss"
                            label="SSS #"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_loan"
                            label="Loans"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_auto_loan"
                            label="Auto Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_philhealth"
                            label="PhilHealth"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_ca"
                            label="Cash Advance"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_coop_loan"
                            label="COOP Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_pagibig"
                            label="Pag-Ibig"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_plan"
                            label="Plan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_coop_save"
                            label="COOP Save"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_tax"
                            label="TAX"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_motor"
                            label="Motor Finance"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_orec"
                            label="Other Receivable"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_sss_loan"
                            label="SSS Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_pagibig_loan"
                            label="Pag-Ibig Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_others"
                            label="Others"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-if="deduction_others>0"
                            v-model="other_details"
                            label="Specify Others"
                            dense
                            outlined
                            :rules="rules.default_max_45_character_and_no_empty_rule"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            background-color="warning"
                            style="font-size: xx-large"
                            v-model="net_salary_formated"
                            label="Net 13th Month Pay"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- alert -->
                <v-col cols="12" v-show="alert">
                  <v-alert
                    color="warning"
                    text
                    class="mb-0"

                  >
                    <div class="d-flex align-start">
                      <v-icon color="warning">
                        {{ icons.mdiAlertOutline }}
                      </v-icon>

                      <div class="ms-3">
                        <p class="text-base font-weight-medium mb-1">
                          {{alert_message}}
                        </p>
                      </div>
                    </div>
                  </v-alert>
                </v-col>
                <v-col cols="12" md="12">
                  <v-btn
                    class="w-full"
                    color="primary"
                    @click="save_employee"
                    v-if="!saving"
                  >
                    Save changes
                  </v-btn>
                  <v-progress-circular
                    :size=50
                    :width="5"
                    color="primary"
                    indeterminate
                    v-else
                  ></v-progress-circular>
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiPencilOutline,} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      is_allowance: false,
      alert_message: '',

      full_name: '',
      branch: '',
      position: '',
      status: '',
      date_of_employment: '',
      employment_duration: '',
      jan: 0,
      feb: 0,
      mar: 0,
      apr: 0,
      may: 0,
      jun: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      oct: 0,
      nov: 0,
      dec: 0,

      gross_salary: 0,
      gross_salary_formated: '0.00',
      new_gross_salary: '0',
      deduction_sss: 0,
      deduction_loan: 0,
      deduction_auto_loan: 0,
      deduction_philhealth: 0,
      deduction_ca: 0,
      deduction_coop_loan: 0,
      deduction_pagibig: 0,
      deduction_plan: 0,
      deduction_coop_save: 0,
      deduction_late: 0,
      deduction_tax: 0,
      deduction_motor: 0,
      deduction_orec: 0,
      deduction_sss_loan: 0,
      deduction_pagibig_loan: 0,
      no_of_absent: 0,
      deduction_others: 0,
      other_details: '',
      net_salary: 0,
      net_salary_formated: '0.00',

      particulars: '',
      category_text: '',

      bank_type: '',
      bank_account_name: '',
      bank_account_no: '',
    }
  }
  export default {
    props: {
      value: Object,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiPencilOutline,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.category_text = this.value.employee.category.category
      this.full_name = this.value.employee.last_name + ', ' + this.value.employee.first_name + ' ' + this.value.employee.middle_name
      this.branch = this.value.employee.branch.branch_code
      this.position = this.value.employee.employee_position.position
      this.status = this.value.employee.status
      this.date_of_employment = moment(this.value.employee.date_of_employment, 'YYYY-MM-DD').format('MMMM DD, YYYY')
      var a = moment();
      var b = moment(this.value.employee.date_of_employment, 'YYYY-MM-DD');

      var years = a.diff(b, 'year');
      b.add(years, 'years');

      var months = a.diff(b, 'months');
      b.add(months, 'months');

      var days = a.diff(b, 'days');
      this.employment_duration = years + ' years ' + months + ' months ' + days + ' days'
      this.compensation_rate = this.value.compensation_rate
      this.particulars = this.value.particulars
      this.bank_type = this.value.bank_type
      this.bank_account_name = this.value.bank_account_name
      this.bank_account_no = this.value.bank_account_no

      this.jan = this.value.jan_amount
      this.feb = this.value.feb_amount
      this.mar = this.value.mar_amount
      this.apr = this.value.apr_amount
      this.may = this.value.may_amount
      this.jun = this.value.jun_amount
      this.jul = this.value.jul_amount
      this.aug = this.value.aug_amount
      this.sep = this.value.sep_amount
      this.oct = this.value.oct_amount
      this.nov = this.value.nov_amount
      this.dec = this.value.dec_amount
      this.is_allowance = (this.value.jan_amount === '0.00' && this.value.feb_amount === '0.00' && this.value.mar_amount === '0.00' && this.value.apr_amount === '0.00' && this.value.may_amount === '0.00' &&
        this.value.jun_amount === '0.00' && this.value.jul_amount === '0.00' && this.value.aug_amount === '0.00' && this.value.sep_amount === '0.00' && this.value.oct_amount === '0.00' && this.value.nov_amount === '0.00'
        && this.value.dec_amount === '0.00')
      this.new_gross_salary = this.value.total_amount
      this.gross_salary = this.value.total_amount

      this.absent_amount = this.value.absent_amount
      this.deduction_sss = this.value.deduction_sss
      this.deduction_sss_loan = this.value.deduction_sss_loan
      this.deduction_loan = this.value.deduction_loan
      this.deduction_auto_loan = this.value.deduction_auto_loan
      this.deduction_philhealth = this.value.deduction_philhealth
      this.deduction_ca = this.value.deduction_ca
      this.deduction_coop_loan = this.value.deduction_coop_loan
      this.deduction_pagibig = this.value.deduction_pagibig
      this.deduction_pagibig_loan = this.value.deduction_pagibig_loan
      this.deduction_plan = this.value.deduction_plan
      this.deduction_coop_save = this.value.deduction_coop_save
      this.deduction_late = this.value.deduction_late
      this.deduction_tax = this.value.deduction_tax
      this.deduction_motor = this.value.deduction_motor
      this.no_of_absent = this.value.no_of_absent
      this.deduction_orec = this.value.deduction_orec
      this.deduction_others = this.value.deduction_others
      this.other_details = this.value.other_details
      this.net_salary = this.value.net_amount


      this.gross_salary_formated = this.formatPrice(this.gross_salary)
      this.net_salary_formated = this.formatPrice(this.net_salary)
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['name']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('thirten_month_pay', ['update_thirten_month_pay']),
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      reset() {
        this.saving = false
        this.particulars = ''
        this.full_name = ''
        this.branch = ''
        this.position = ''
        this.status = ''
        this.date_of_employment = ''
        this.employment_duration = ''
        this.bank_type = ''
        this.bank_account_name = ''
        this.bank_account_no = ''
        this.jan = 0
        this.feb = 0
        this.mar = 0
        this.apr = 0
        this.may = 0
        this.jun = 0
        this.jul = 0
        this.aug = 0
        this.sep = 0
        this.oct = 0
        this.nov = 0
        this.dec = 0
        this.gross_salary = 0
        this.gross_salary_formated = '0.00'
        this.deduction_sss = 0
        this.deduction_loan = 0
        this.deduction_auto_loan = 0
        this.deduction_philhealth = 0
        this.deduction_ca = 0
        this.deduction_coop_loan = 0
        this.deduction_pagibig = 0
        this.deduction_plan = 0
        this.deduction_coop_save = 0
        this.deduction_late = 0
        this.deduction_tax = 0
        this.deduction_motor = 0
        this.deduction_orec = 0
        this.deduction_pagibig_loan = 0
        this.deduction_sss_loan = 0
        this.no_of_absent = 0
        this.deduction_others = 0
        this.other_details = ''
        this.net_salary = 0
        this.net_salary_formated = '0.00'
        this.selectedDepositId = -1
      },
      computation_of_salary() {
        this.check_if_input_number_correct()
        var total_amounttt = (parseFloat(this.jan)
          + parseFloat(this.feb)
          + parseFloat(this.mar)
          + parseFloat(this.apr)
          + parseFloat(this.may)
          + parseFloat(this.jun)
          + parseFloat(this.jul)
          + parseFloat(this.aug)
          + parseFloat(this.sep)
          + parseFloat(this.oct)
          + parseFloat(this.nov)
          + parseFloat(this.dec)) / 12;
        if (this.is_remove_dec) {
          total_amounttt = (parseFloat(this.jan)
            + parseFloat(this.feb)
            + parseFloat(this.mar)
            + parseFloat(this.apr)
            + parseFloat(this.may)
            + parseFloat(this.jun)
            + parseFloat(this.jul)
            + parseFloat(this.aug)
            + parseFloat(this.sep)
            + parseFloat(this.oct)
            + parseFloat(this.nov)) / 12;
        }
        var deductions = parseFloat(this.deduction_sss) + parseFloat(this.deduction_loan) + parseFloat(this.deduction_auto_loan) + parseFloat(this.deduction_philhealth) + parseFloat(this.deduction_ca) + parseFloat(this.deduction_coop_loan) + parseFloat(this.deduction_pagibig) + parseFloat(this.deduction_plan) + parseFloat(this.deduction_coop_save) + parseFloat(this.deduction_late) + parseFloat(this.deduction_tax) + parseFloat(this.deduction_motor) + parseFloat(this.deduction_others) + parseFloat(this.deduction_orec) + parseFloat(this.deduction_sss_loan) + parseFloat(this.deduction_pagibig_loan)
        this.gross_salary = total_amounttt
        this.net_salary = parseFloat(total_amounttt) - parseFloat(deductions)
        if (this.is_allowance) {
          this.gross_salary = this.new_gross_salary
        }
        this.net_salary = parseFloat(this.gross_salary) - parseFloat(deductions)

        this.gross_salary_formated = this.formatPrice(this.gross_salary)
        this.net_salary_formated = this.formatPrice(this.net_salary)
      },
      check_if_input_number_correct() {
        if (this.no_of_days > 31 || this.no_of_days < 0 || this.no_of_days === '') {
          this.no_of_days = 0
        }
        if (this.holiday_pay < 0 || this.holiday_pay === '') {
          this.holiday_pay = 0
        }
        if (this.rice_allowance < 0 || this.rice_allowance === '') {
          this.rice_allowance = 0
        }
        if (this.birthday_allowances < 0 || this.birthday_allowances === '') {
          this.birthday_allowances = 0
        }
        if (this.trainee_allowances < 0 || this.trainee_allowances === '') {
          this.trainee_allowances = 0
        }
        if (this.incentives_allowances < 0 || this.incentives_allowances === '') {
          this.incentives_allowances = 0
        }
        if (this.bod_allowance < 0 || this.bod_allowance === '') {
          this.bod_allowance = 0
        }
        if (this.deduction_sss < 0 || this.deduction_sss === '') {
          this.deduction_sss = 0
        }
        if (this.deduction_loan < 0 || this.deduction_loan === '') {
          this.deduction_loan = 0
        }
        if (this.deduction_auto_loan < 0 || this.deduction_auto_loan === '') {
          this.deduction_auto_loan = 0
        }
        if (this.deduction_philhealth < 0 || this.deduction_philhealth === '') {
          this.deduction_philhealth = 0
        }
        if (this.deduction_ca < 0 || this.deduction_ca === '') {
          this.deduction_ca = 0
        }
        if (this.deduction_coop_loan < 0 || this.deduction_coop_loan === '') {
          this.deduction_coop_loan = 0
        }
        if (this.deduction_pagibig < 0 || this.deduction_pagibig === '') {
          this.deduction_pagibig = 0
        }
        if (this.deduction_plan < 0 || this.deduction_plan === '') {
          this.deduction_plan = 0
        }
        if (this.deduction_coop_save < 0 || this.deduction_coop_save === '') {
          this.deduction_coop_save = 0
        }
        if (this.deduction_late < 0 || this.deduction_late === '') {
          this.deduction_late = 0
        }
        if (this.no_of_absent < 0 || this.no_of_absent === '') {
          this.no_of_absent = 0
        }
        if (this.deduction_tax < 0 || this.deduction_tax === '') {
          this.deduction_tax = 0
        }
        if (this.deduction_motor < 0 || this.deduction_motor === '') {
          this.deduction_motor = 0
        }
        if (this.deduction_others < 0 || this.deduction_others === '') {
          this.deduction_others = 0
        }

        if (this.deduction_orec < 0 || this.deduction_orec === '') {
          this.deduction_orec = 0
        }
        if (this.deduction_sss_loan < 0 || this.deduction_sss_loan === '') {
          this.deduction_sss_loan = 0
        }
        if (this.deduction_pagibig_loan < 0 || this.deduction_pagibig_loan === '') {
          this.deduction_pagibig_loan = 0
        }
        if (this.new_gross_salary < 0 || this.new_gross_salary === '') {
          this.new_gross_salary = 0
        }
      },
      save_employee() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.value.id);

          data.append('deduction_sss', this.deduction_sss)
          data.append('deduction_philhealth', this.deduction_philhealth)
          data.append('deduction_pagibig', this.deduction_pagibig)
          data.append('deduction_loan', this.deduction_loan)
          data.append('deduction_auto_loan', this.deduction_auto_loan)
          data.append('deduction_coop_loan', this.deduction_coop_loan)
          data.append('deduction_late', this.deduction_late)
          data.append('deduction_coop_save', this.deduction_coop_save)
          data.append('deduction_ca', this.deduction_ca)
          data.append('deduction_plan', this.deduction_plan)
          data.append('deduction_others', this.deduction_others)
          data.append('other_details', this.other_details)
          data.append('deduction_tax', this.deduction_tax)
          data.append('deduction_motor', this.deduction_motor)
          data.append('deduction_pagibig_loan', this.deduction_pagibig_loan)
          data.append('deduction_sss_loan', this.deduction_sss_loan)
          data.append('deduction_orec', this.deduction_orec)

          data.append('total_amount', this.gross_salary);
          data.append('net_amount', this.net_salary);

          this.update_thirten_month_pay(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.$emit('data', true)
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    },
  }
</script>
